import React from 'react'
import classnames from 'classnames'
import { intlShape } from 'react-intl'
import { Form, Field, reduxForm } from 'redux-form'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Button, Input } from '../../components/common'
import { validation } from '../../utils'
import { changePassword } from '../../store/actions/profile'
import './_index.scss'

interface IProp{
  className?: string;
  handleSubmit: (event: any) => any;
  isChanging: boolean;
  onClosedModal: () => void;
  changePassword: () => any;
  isNew: boolean;
  invalid: boolean;
}

interface IState{
}

class ChangePassword extends React.Component<IProp, IState> {
  static contextTypes = {
    intl: intlShape,
  }

  componentDidMount(){
  }

  componentWillUnmount(){
  }

  submit = () => {
    this.props.changePassword()
      .then(() => this.props.onClosedModal())
      .catch(() => {})
  }

  render() {
    const {
      className,
      isChanging,
      invalid,
      isNew
    } = this.props

    const classes = classnames(
      'change-password',
      className)

    return (
      <div className={classes}>
        <Form 
          onSubmit={ this.props.handleSubmit(this.submit)}>
          <div className="change-password__title">
            <h4>{ this.context.intl.formatMessage({ id: 'titleChangePassword', defaultMessage: 'titleChangePassword'}) }</h4>
          </div>
          <div className="change-password__form" id="modal-detail">
            { isNew && <p>{ this.context.intl.formatMessage({ id: 'contentChangePasswordFirst', defaultMessage: 'contentChangePasswordFirst'}) }</p>}
            <p>{ this.context.intl.formatMessage({ id: 'contentChangePassword', defaultMessage: 'contentChangePassword'}) }</p>
            
            <Field 
              name="old_password"
              component={Input}
              type="password"
              placeholder="placeholderOldPassword"  
              label="labelOldPassword"
              id="old_password"
              isRequired
              validate={[validation.required]}
            />

            <Field 
              name="new_password"
              component={Input}
              type="password"
              placeholder="placeholderNewPassword"  
              label="labelNewPassword"
              id="new_password"
              isRequired
              validate={[validation.required, validation.passwordUpLowSymbol8]}
            />

            <Field 
              name="new_password_repeat"
              component={Input}
              type="password"
              placeholder="placeholderNewPasswordRepeat"  
              label="labelNewPasswordRepeat"
              id="new_password_repeat"
              isRequired
              validate={[validation.required, validation.passwordUpLowSymbol8, validation.passwordsMustMatch]}
            />
          </div>
          <div className="change-password__action">
            <Button 
              color="primary-dark"
              label="buttonChangePassword"
              type="fluid"
              disabled={invalid || isChanging}
              showSpinner={isChanging}
            />
          </div>
        </Form>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    isChanging: state.profile.isChanging,
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  changePassword
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm<any, any>({
    form: 'changePassword'
  })(ChangePassword)
)