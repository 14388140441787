import moment from 'moment'

// Name for localstorage
export enum LS {
  TOKEN = 'NBA-ATTENDANCE-TOKEN',
  LANG = 'NBA-ATTENDANCE-LANG',
  PROFILE = 'NBA-ATTENDANCE-PROFILE',
  PROFILE_STAT = 'NBA-ATTENDANCE-PROFILE-STAT',
  MASTER = 'NBA-ATTENDANCE-MASTER',
  ATT = 'NBA-ATTENDANCE-ATT',
  ATT_LATEST = 'NBA-ATTENDANCE-ATT-LATEST',
  ATT_ACTIVE = 'NBA-ATTENDANCE-ATT-ACtIVE',
  APP = 'NBA-ATTENDANCE-APP',
  INBOX = 'NBA-ATTENDANCE-INBOX',
  RECENT = 'NBA-ATTENDANCE-INBOX-RECENT',
  BROADCAST = 'NBA-ATTENDANCE-INBOX-BROADCAST',
  EMERGENCY = ' NBA-ATTENDANCE-INBOX-EMERGENCY',
  TEMP_EMERGENCY = 'NBA-ATTENDANCE-TEMP-EMERGENCY'
}

// Name for cookie
export enum CK {
  APP = 'NBA-ATTENDANCE-APP-APPROVAL-FILTER',
  INB = 'NBA-ATTENDANCE-APP-INBOX-FILTER',
  ATT = 'NBA-ATTENDANCE-APP-ATTENDANCE-FILTER',
  VISIT = 'NBA-ATTENDANCE-APP-VISIT'
}

// Name for Path
export enum PT {
  SIGNIN = '/signin',
  RENEWPASS = '/renewpassword',
  FORGOTPASS = '/forgotpassword',
  RESETPASS = '/reset-password',
  HOMEPAGE = '/homepage',
  ATTENDANCE = '/attendance',
  APPROVAL = '/approval',
  BROADCAST = '/broadcast',
  SETTING = '/setting',
  INBOX = '/inbox'
}

// Leave reason
export enum ATYPE {
  SICK = 'SAKIT',
  PERMIT = 'IZIN',
  ANNUAL_LEAVE = 'CUTI_TAHUNAN',
  SPECIAL_LEAVE = 'CUTI_KHUSUS'
}

// Attendance status
export enum STATUS {
  DRAFT = 101,
  DELETED = 102,
  SUBMITTED = 201,
  ON_REVISION = 202,
  APPROVED = 301
}

// User status
export enum USTATUS {
  NEW = 101,
  ACTIVE = 201,
  INACITVE = 202
}

// language
export enum LANG {
  EN = 'en',
  ID = 'id'
}

// Inbox type
export enum ITYPE {
  MANAGEMENT = 1,
  SAFETY = 2
}

// Content type
export enum CTYPE {
  ARTICLE = 'article',
  IMAGE = 'image',
  VIDEO = 'video'
}

export enum MESSAGE_TYPE {
  MANAGEMENT = 1,
  SAFETY = 2
}

export enum CONTENT_TYPE {
  ARTICLE = 'article',
  IMAGE = 'image',
  VIDEO = 'video'
}

export const LATE_TIME = moment().startOf('d').add(9, 'h').add(59, 'seconds')