import React from 'react'
import ReactDOM from 'react-dom'
import classnames from 'classnames'
import ReactModal from 'react-modal'
import Button from '../Button'
import { intlShape } from 'react-intl'
import './_index.scss'

ReactModal.setAppElement('#root')

interface IProp {
  mode?: number;
  title?: string;
  onClosed?: any;
  onConfirmed?: any;
  content?: any;
  cancelTranslation?: string;
  confirmTranslation?: string;
  hideClose?: boolean;
  changedValue?: any;
}

interface IState {
  isOpen: boolean;
  offsetY: number;
  touchStartY: number | null;
  scrollOffset: number | null;
  transform: 'string' | null;
}

class Modal extends React.Component<IProp, IState> {
  static contextTypes = {
    intl: intlShape,
  }
  private modal: React.RefObject<any>

  constructor(props: IProp) {
    super(props)

    this.modal = React.createRef<any>()
    this.state = {
      isOpen: false,
      offsetY: 0,
      touchStartY: null,
      scrollOffset: null,
      transform: null
    }
  }

  componentDidMount() {
    if ((this.props.mode === 4 || this.props.mode === 5) && this.modal && this.modal.current) {
      this.modal.current.node.addEventListener('touchstart', this.onTouchStart)
      this.modal.current.node.addEventListener('touchmove', this.onTouchMove)
      this.modal.current.node.addEventListener('touchend', this.onTouchEnd)
    }
  }

  componentWillUnmount() {
    if ((this.props.mode === 4 || this.props.mode === 5) && this.modal && this.modal.current) {
      this.modal.current.node.removeEventListener('touchstart', this.onTouchStart)
      this.modal.current.node.removeEventListener('touchmove', this.onTouchMove)
      this.modal.current.node.removeEventListener('touchend', this.onTouchEnd)
    }
  }

  onTouchStart = (event: any) => {
    if (event.touches[0]) {
      const detailNode = ReactDOM.findDOMNode(this.modal.current.node.querySelector('#modal-detail'))
      const scrollOffset = (detailNode as Element).scrollTop
      const touchStartY = event.touches[0].clientY
      this.setState({ touchStartY, scrollOffset })
    }
  }

  onTouchMove = (event: any) => {
    if (event.touches[0]) {
      const detailNode = ReactDOM.findDOMNode(this.modal.current.node.querySelector('#modal-detail'))
      const scrollOffset = (detailNode as Element).scrollTop
      const currentY = event.touches[0].clientY
      const startY = this.state.touchStartY || 0
      const offsetY = currentY - startY - (this.state.scrollOffset || 0)

      if (offsetY > 0 && scrollOffset === 0) {
        if (event.cancelable) event.preventDefault()

        this.setState({ offsetY })
      }
    }
  }

  onTouchEnd = () => {
    /**
     * this.state.offsetY > 250
     * Close window if top position more than 250px
     * 
     * this.state.offsetY < window.outerHeight
     * to make sure state offsetY is correct value
     * because sometime clientY return same as outerHeight
     */
    const { hideClose } = this.props

    if (!hideClose && this.state.offsetY > 200 && this.state.offsetY < window.outerHeight) {
      this.closeModal()
    } else {
      this.setState({
        offsetY: 0,
        touchStartY: null,
        scrollOffset: null
      })
    }
  }

  openModal = () => this.setState({ isOpen: true })

  closeModal = () => {
    this.setState({
      isOpen: false,
      offsetY: window.outerHeight,
      touchStartY: null,
      scrollOffset: null
    }, () => {
      if (typeof this.props.onClosed === 'function') {
        this.props.onClosed()
      }
    })
  }

  confirm = () => this.setState({ isOpen: false }, () => this.props.onConfirmed())

  render() {
    const {
      title,
      content,
      cancelTranslation,
      confirmTranslation,
      hideClose,
      changedValue,
      mode = 1 // 1 popup, 2 confirm, 3 element, 4 card, 5 card-transparent
    } = this.props

    const {
      intl
    } = this.context

    const {
      offsetY
    } = this.state

    const classes = classnames('modal',
      { 'modal--el': mode === 3 },
      { 'modal--card': mode === 4 },
      { 'modal--card modal--transparent': mode === 5 })

    const overlayClasses = classnames('modal-overlay',
      { 'modal-overlay--transparent': mode === 5 })

    return (
      <ReactModal
        isOpen={this.state.isOpen}
        onRequestClose={this.closeModal}
        className={classes}
        overlayClassName={overlayClasses}
        shouldCloseOnOverlayClick={!hideClose}
        ref={this.modal}
        style={{
          content: {
            top: (mode === 4 || mode === 5) ? `${offsetY + 90}px` : '50%'
          }
        }}
      >
        {(mode === 4 || mode === 5) && !hideClose &&
          <Button
            type="button-close-card"
            icon="clear"
            onClick={this.closeModal}
          />
        }

        {(mode < 4) &&
          <div className="modal__header">
            <div className="modal__header__text">
              {title && intl.formatMessage({ id: title, defaultMessage: title })}
            </div>
            <Button
              type="button-close"
              icon="clear"
              onClick={this.closeModal}
            />
          </div>
        }

        {mode < 3 &&
          <div className="modal__body">
            <div className="modal__body__content">
              { content && 
              changedValue ? changedValue //intl.formatMessage({ id: content, defaultMessage: content }).replace('value', changedValue)
                : 
                intl.formatMessage({ id: content, defaultMessage: content })}
            </div>
          </div>
        }

        {(mode === 3 || mode === 4 || mode === 5) && this.props.content}

        {mode < 3 &&
          <div className="modal__action">
            {mode < 3 &&
              <Button
                color="transparent-danger"
                label={cancelTranslation || "buttonCancel"}
                onClick={this.closeModal}
              />
            }

            {mode === 2 &&
              <Button
                color="transparent"
                label={confirmTranslation || changedValue ? "buttonContinue" : "buttonConfirm"}
                onClick={this.confirm}
              />
            }
          </div>
        }
      </ReactModal>
    )
  }
}

export default Modal