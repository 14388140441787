export const CONF = {
  LANG: '@@config/LANG',
  LANG_CHANGING: '@@config/LANG_CHANGING'
}
export const ALERT = {
  SHOW: '@@alert/SHOW',
  HIDE: '@@alert/HIDE',
}
export const PROF = {
  GET: '@@profile/GET',
  GET_STAT: '@@profile/GET_STAT',
  GET_SIGNED: '@@profile/GET_SIGNED',
  GET_LOADING: '@@profile/GET_LOADING',
  PASS_CHANGING: '@@profile/PASS_CHANGING',
}
export const ATT = {
  GET: '@@attendance/GET',
  GET_LATEST: '@@attendance/GET_LATEST',
  GET_ACTIVE: '@@attendance/GET_ACTIVE',
  GET_LOADING: '@@attendance/GET_LOADING',
  GET_ACTIVE_LOADING: '@@attendance/GET_ACTIVE_LOADING',
  FILTER: '@@attendance/FILTER',
  FILTER_LOADING: '@@attendance/FILTER_LOADING',
  GET_LOADING_DETAIL: '@@attendance/GET_LOADING_DETAIL',
  GET_DETAIL: '@@attendance/GET_DETAIL',
  START_LOADING: '@@attendance/START_LOADING',
  START_DELETING: '@@attendance/START_DELETING',
  END_LOADING: '@@attendance/END_LOADING',
  REVISE_LOADING: '@@attendance/REVISE_LOADING'
}
export const APP = {
  GET: '@@approval/GET',
  GET_LOADING: '@@approval/GET_LOADING',
  FILTER: '@@approval/FILTER',
  FILTER_LOADING: '@@approval/FILTER_LOADING',
  APPROVE_LOADING_START: '@@approval/APPROVE_LOADING_START',
  APPROVE_LOADING_END: '@@approval/APPROVE_LOADING_END',
  REJECT_LOADING_START: '@@approval/REJECT_LOADING_START',
  REJECT_LOADING_END: '@@approval/REJECT_LOADING_END',
  BULK_LOADING_START: '@@approval/BULK_LOADING_START',
  BULK_LOADING_END: '@@approval/BULK_LOAIDNG_END',
}
export const MASTER = {
  GET: '@@master/GET',
  GET_LOADING: '@@master/GET_LOADING'
}
export const RESET = {
  ALL: '@@reset/RESET_ALL'
}
export const SIGNIN = {
  LOADING: '@@signin/LOADING',
  ERROR: '@@signin/ERROR', 
  RENEW_PASS_LOADING: '@@renew_password/LOADING',
  FORGOT_PASS_LOADING: '@@forgot_password/LOADING',
  RESET_PASS_LOADING: '@@reset_password/LOADING',
  RESET_PASS_CHECK_SUCCESS: '@@reset_password_check/SUCCESS',
}
export const INBOX = {
  FILTER: '@@inbox/FILTER',
  FILTER_LOADING: '@@inbox/FILTER_LOADING',
  GET: '@@inbox/GET',
  GET_LOADING: '@@inbox/GET_LOADING',
  DETAIL: '@@inbox/DETAIL',
  DETAIL_LOADING: '@@inbox/DETAIL_LOADING',
  MARK_AS_READ: '@@inbox/MARK_AS_READ',
  RECENT: '@@inbox/RECENT',
  RECENT_LOADING: '@@inbox/RECENT_LOADING',
  BROADCAST: '@@inbox/BROADCAST',
  BROADCAST_LOADING: '@@inbox/BROADCAST_LOADING'
}

export const EMERGENCY = {
  GET: '@@emergency/GET'
}