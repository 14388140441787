import { ALERT, RESET } from '../../definitions'

const initialState = {
  isShowing: false,
  message: '',
  values: {},
  type: 'warning'
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case ALERT.SHOW:
      return Object.assign({},
        {
          ...action.alert,
          isShowing: true
        })

    case ALERT.HIDE:
      return initialState

    case RESET.ALL:
      return initialState

    default:
      return state
  }
}