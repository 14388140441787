import React from 'react'
import Cleave from 'cleave.js/react'
import { intlShape } from 'react-intl'
import { change } from 'redux-form'
import classnames from 'classnames'
import Icon from '../Icon'
import Button from '../Button'
import 'react-dates/initialize'
import { DateRangePicker } from 'react-dates'
import moment from 'moment'
import Select from 'react-select'
import 'react-dates/lib/css/_datepicker.css'
import './_index.scss'
import { HexBase64BinaryEncoding } from 'crypto';

interface IProp{
  meta?: any;
  input?: any;
  checked?: boolean;
  placeholder?: string;
  label?: string;
  id: string;
  isRequired?: boolean;
  isClearable?: boolean;
  type?: string;
  onCreditCardTypeChanged?: any;
  className?: string;
  iconColor?: string;
  icon?: string;
  onChange?: any;
  showNull?: boolean;
  onFileSelected?: any;
  initialize?: any;
  options?: IOpt[];
  value?: any;
  takePhotoValue?: HexBase64BinaryEncoding | string | null;
  dateRangeFullScreen?: boolean;
  minDate?: any;
  maxDate?: any;
  suffix?: any;
}

interface IState{
  focusedInput: any;
  startDate: any;
  endDate: any;
}

interface IOpt{
  label: string;
  value: any;
  isTranslate?: boolean;
}

class Input extends React.Component<IProp, IState> {
  static contextTypes = {
    intl: intlShape,
  }
  private takePhotoRef: React.RefObject<any>

  constructor(props: IProp){
    super(props)

    this.takePhotoRef = React.createRef<any>()
    this.state = {
      focusedInput: null,
      startDate: props.input && props.input.value && props.input.value.startDate ? moment(props.input.value.startDate) : moment().startOf('month'),
      endDate: props.input && props.input.value && props.input.value.endDate ? moment(props.input.value.endDate) : moment().endOf('month')
    }
  }

  /**
   * dispatch to change form state value into empty string
   */
  clearInput = () => {
    this.props.meta.dispatch(change(this.props.meta.form, this.props.input.name, ''))
  }
  
  render() {
    const {
      className,
      placeholder,
      input,
      label,
      id,
      isRequired,
      isClearable,
      meta,
      type,
      icon,
      iconColor,
      onCreditCardTypeChanged,
      options,
      showNull,
      onFileSelected,
      takePhotoValue,
      minDate,
      maxDate,
      dateRangeFullScreen,
      suffix,
      ...otherProps
    } = this.props

    const classes = 
      classnames('input',
        { 'input--with-icon': icon},
        `input--with-icon--${iconColor}`,
        className)

    let inputDOM = null

    switch (type) {
      case 'cc_number':
        inputDOM = 
        <Cleave 
          className="input__wrapper__input"
          {...input} 
          {...otherProps} 
          options={{
            creditCard: true,
            onCreditCardTypeChanged: onCreditCardTypeChanged
          }} />
        break
      case 'daterange': 
        inputDOM = 
          <DateRangePicker
            orientation="vertical"
            withFullScreenPortal={dateRangeFullScreen}
            startDate={this.state.startDate} 
            startDateId="dateRangeStartDate" 
            endDate={this.state.endDate}
            enableOutsideDays={true}
            isOutsideRange={(day) => {
              if(minDate && day < moment(minDate)) return true
              if(maxDate && day > moment(maxDate)) return true
              return false
            }}
            minimumNights={0}
            endDateId="dateRangeEndDate" 
            onDatesChange={({ startDate, endDate }) => {
              this.setState({ startDate, endDate })
              input.onChange({ startDate, endDate })}
            } 
            displayFormat={'ll'}
            hideKeyboardShortcutsPanel
            readOnly={true}
            focusedInput={this.state.focusedInput} 
            onFocusChange={focusedInput => this.setState({ focusedInput })}
            initialVisibleMonth={() => moment()} 
          />
        break
      case 'cc_valid_until':
        inputDOM = 
          <Cleave 
            className="input__wrapper__input"
            {...input} 
            {...otherProps} 
            placeholder={this.context.intl.formatMessage({ id: placeholder, defaultMessage: placeholder })}
            options={{
              date: true,
              datePattern: ['m', 'y']
            }} />
        break
      case 'checkbox':
        inputDOM = 
          <>
            <input
              className="input__wrapper__checkbox"
              id={id}
              {...input}
              {...otherProps}
              type={type}
            />
            <label
              className="input__wrapper__checkbox-label"
              htmlFor={id}>
              { placeholder && this.context.intl.formatMessage({ id: placeholder, defaultMessage: placeholder })}
            </label>
          </>
        break
      case 'radio':
        inputDOM = 
          <>
            <input
              className="input__wrapper__radio"
              id={id}
              {...input}
              {...otherProps}
              type={type}
            />
            <label
              className="input__wrapper__radio-label"
              htmlFor={id}>
              { placeholder && this.context.intl.formatMessage({ id: placeholder, defaultMessage: placeholder })}
            </label>
          </>
        break
      case 'select':
        inputDOM = 
          <select
            className="input__wrapper__select"
            id={id}
            placeholder={this.context.intl.formatMessage({ id: placeholder, defaultMessage: placeholder })}
            {...input}
            {...otherProps}
          >
            { showNull && 
              <option value="">{this.context.intl.formatMessage({ id: placeholder, defaultMessage: placeholder })}</option>
            }
            { options && options.map((option: IOpt, index: number) => (
              <option key={index} value={option.value}>{ option.isTranslate ? this.context.intl.formatMessage({ id: option.label, defaultMessage: option.label }) : option.label }</option>
            )) }
          </select>
        break
      case 'multi-select':
        const translatedValue = input.value ? input.value.map((val: any) => (
          { value: val.value, label: val.isTranslate ? this.context.intl.formatMessage({ id: val.label, defaultMessage: val.label }) : val.label }
        )) : undefined

        inputDOM = 
          <Select
            {...input}
            {...otherProps}
            id={id}
            className="input__wrapper__multi-select"
            classNamePrefix="input__wrapper__multi-select"
            onChange={(value: any) => input.onChange(value)}
            options={options && options.map((option: IOpt ) => (
              { value: option.value, label: option.isTranslate ? this.context.intl.formatMessage({ id: option.label, defaultMessage: option.label }) : option.label }
            ))}
            placeholder={this.context.intl.formatMessage({ id: placeholder, defaultMessage: placeholder })}
            noOptionsMessage={() => this.context.intl.formatMessage({ id: 'labelNoOptions', defaultMessage: 'labelNoOptions' })}
            isSearchable={false}
            value={translatedValue}
            isMulti={true}
            onBlur={() => {}}
          />
        break
      case 'textarea':
        inputDOM = 
          <textarea
            className="input__wrapper__input"
            id={id}
            placeholder={this.context.intl.formatMessage({ id: placeholder, defaultMessage: placeholder })}
            {...input}
            {...otherProps}
          />
        break
      case 'take-photo':
        delete input.value
        inputDOM = 
          <>
            <div className="input__wrapper__take-photo">
              { takePhotoValue && <img src={takePhotoValue} /> }
              <Button 
                color="dark"
                icon="camera"
                type="fluid"
                label={placeholder}
                onClick={() => this.takePhotoRef.current.click()}
              />
            </div>

            <input
              className="input__wrapper__file"
              id={id}
              placeholder={this.context.intl.formatMessage({ id: placeholder, defaultMessage: placeholder })}
              ref={this.takePhotoRef}
              {...input}
              {...otherProps}
              onChange={(e: any) => {
                if(e.target && e.target.files && e.target.files.length > 0) onFileSelected(e.target.files[0])
              }}
              type="file"
            />
          </>
        break
      default:
        inputDOM = 
          <input
            className="input__wrapper__input"
            id={id}
            placeholder={this.context.intl.formatMessage({ id: placeholder, defaultMessage: placeholder })}
            {...input}
            {...otherProps}
            type={type}
            
          />
    }

    return (
      <div className={classes}>
        { label &&
          <label
            className="input__label"
            htmlFor={id}>
            {this.context.intl.formatMessage({ id: label, defaultMessage: label })}
            {isRequired &&
              <span className="input__label__required">*</span>
            }
          </label>
        }

        <div className="input__wrapper">
          { icon &&
            <div className="input__wrapper__icon">
              <Icon data={icon} />
            </div>
          }
          
          {inputDOM}
          {suffix && 
            <div className="input__wrapper__suffix">
              {suffix}
            </div>
          }
          
          <div className="input__wrapper__actions">
            { isClearable && this.props.meta.dirty &&
              <span className="input__actions__clear" onClick={this.clearInput}><Icon data={'close'} /></span>
            }
          </div>
        </div>
        <div className="input__validation">
          { meta && meta.touched && meta.error && <span className="input__error">{meta.error}</span> }
        </div>
      </div>
    )
  }
}

export default Input