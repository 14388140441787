import api from '../../../api'
import { APP, ALERT } from '../../definitions'
import { storage, cookie } from '../../../utils'
import { IAppRes, IAttRes, IAttHistoryObjRes } from '../../../config/interface'
import { alert, logger } from '../../../utils'
import { getProfileStat } from '../profile'
import { settings } from '../../../config/config'
import moment from 'moment'
import bluebird from 'bluebird'

const getApproval = () => {  
  return (dispatch: any, getState: any) => {
    return new Promise((resolve, reject) => {
    // Dispatch temporary data from localstorage
      dispatch({ type: APP.GET_LOADING, isLoading: true })
        
      let temp = storage.getApp()
      if(temp){
        dispatch({ 
          type: APP.GET, 
          data: JSON.parse(temp)
        })
      } 

      const { approval } = getState()
      const query = {
        ...(approval.filter.start && { start: moment(approval.filter.start).format('YYYY-MM-DD') }),
        ...(approval.filter.end && { end: moment(approval.filter.end).format('YYYY-MM-DD') }),
        ...(approval.filter.isWorking.length > 0 && {
          is_working: approval.filter.isWorking.map((option: any) => option.value).join()
        }),
        ...(approval.filter.status.length > 0 && {
          status: approval.filter.status.map((option: any) => option.value).join()
        }),
        dir: approval.filter.dir
      }

      // Api call
      api.getApproval(query)
        .then((result: any ) => {
          let approval = result.data.map((app: IAppRes) => ({
            date: app.date,
            attendance: app.attendance.map((att: IAttRes) => ({
              id: att.id,
              user: att.user,
              approver: att.approver,
              isWorking: att.is_working,
              status: att.status,
              date: att.date,
              ...(att.start && att.start.timestamp && {
                start: {
                  timestamp: att.start.timestamp,
                  serverTimestamp: att.start.server_timestamp,
                  latitude: att.start.latitude,
                  longitude: att.start.longitude
                },
              }),
              ...(att.is_late && { isLate: att.is_late }),
              ...(att.is_remote && { isRemote: att.is_remote }),
              ...(att.absence_type && { absenceType: att.absence_type }),
              ...(att.absence_reason && { absenceReason: att.absence_reason }),
              ...(att.attachment_url && { attachmentUrl: att.attachment_url }),
              ...(att.end && att.end.timestamp && { end: 
              {
                timestamp: att.end.timestamp,
                serverTimestamp: att.end.server_timestamp,
                latitude: att.end.latitude,
                longitude: att.end.longitude
              }
              }),
              ...(att.status_history && {
                statusHistory: att.status_history.map((his: IAttHistoryObjRes) => ({
                  status: his.status,
                  ...(his.remarks && { remarks: his.remarks }),
                  createdAt: his.created_at,
                  createdBy: his.created_by
                }))
              })
            }))
          }))

          storage.setApp(approval)

          return dispatch({
            type: APP.GET,
            data: approval
          })
        })
        .then(() => dispatch({ type: APP.GET_LOADING, isLoading: false }))
        .then(() => resolve())
        .catch((err) => {
          logger(err, 'error', 'getApproval', false, err && err.response && err.response.data, query)
          dispatch({ type: APP.GET_LOADING, isLoading: false })
          reject()
        })
    })
  }
}

const changeFilter = (isReset?: boolean) => {
  return (dispatch: any, getState: any) => {
    return new Promise((resolve) => {
      const { form } = getState()
    
      const data = isReset ? {
        start: settings.app.start,
        end: settings.app.end,
        isWorking: settings.app.isWorking,
        status: settings.app.status,
        dir: settings.att.dir
      } : {
        start: form.appFilter.values.daterange.startDate,
        end: form.appFilter.values.daterange.endDate,
        isWorking: form.appFilter.values.is_working ? form.appFilter.values.is_working : [],
        status: form.appFilter.values.status ? form.appFilter.values.status : [],
        dir: form.appFilter.values.dir
      }

      dispatch({ type: APP.FILTER_LOADING, isLoadingFilter: true })
      dispatch({
        type: APP.FILTER,
        data
      })

      cookie.setAppSetting(data)

      dispatch(getApproval())
        .then(() => dispatch({ type: APP.FILTER_LOADING, isLoadingFilter: false }))
        .then(() => resolve())
    })
  }
}

const approveAttendance = (id: number) => {
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: APP.APPROVE_LOADING_START, isApproving: true, id: id })

      // Api call
      api.approveAttendance(id)
        .then(() => dispatch(getApproval()))
        .then(() => dispatch({ type: ALERT.SHOW, alert: alert(200, 'approveAttendance')}))
        .then(() => dispatch({ type: APP.APPROVE_LOADING_END, isApproving: false, id: id }))
        .then(() => dispatch(getProfileStat()))
        .then(() => resolve())
        .catch((err: any) => {
          dispatch({ type: APP.APPROVE_LOADING_END, isApproving: false, id: id })
          dispatch({ type: ALERT.SHOW, alert: err && err.response ? 
            alert(err.response.status, 'approveAttendance', err.response.data && err.response.data.error ? err.response.data.error.code : null ): 
            alert(0, 'approveAttendance') })
          logger(err, 'error', 'approveAttendance', false, err && err.response && err.response.data, id)
          reject()
        })
    })
  }
}

const bulkApprove = (arrId: number[]) => {
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: APP.BULK_LOADING_START, isApproving: true, arrId: arrId })
      const promiseList = arrId.map( (id: number) => api.approveAttendance(id))

      bluebird.all(promiseList)
        .then(() => dispatch(getApproval()))
        .then(() => dispatch({ type: ALERT.SHOW, alert: alert(200, 'bulkApproveAttendance')}))
        .then(() => dispatch({ type: APP.BULK_LOADING_END, isApproving: false, arrId: arrId }))
        .then(() => dispatch(getProfileStat()))
        .then(() => resolve())
        .catch((err: any) => {
          dispatch({ type: APP.BULK_LOADING_END, isApproving: false, arrId: arrId })
          dispatch({ type: ALERT.SHOW, alert: err && err.response ? 
            alert(err.response.status, 'bulkApproveAttendance', err.response.data && err.response.data.error ? err.response.data.error.code : null ): 
            alert(0, 'bulkApproveAttendance') })
          logger(err, 'error', 'bulkApprove', false, err && err.response && err.response.data, arrId)
          reject()
        })
    })
  }
}

const rejectAttendance = (id: number) => {
  return (dispatch: any, getState: any) => {
    return new Promise((resolve, reject) => {
      const { form } = getState()

      dispatch({ type: APP.REJECT_LOADING_START, isRejecting: true, id: id })

      const payload = { 
        remarks: form.attDetail && form.attDetail.values ? form.attDetail.values.remarks : '' 
      }

      // Api call
      api.rejectAttendance(id, payload)
        .then(() => dispatch(getApproval()))
        .then(() => dispatch({ type: ALERT.SHOW, alert: alert(200, 'rejectAttendance')}))
        .then(() => dispatch({ type: APP.REJECT_LOADING_END, isRejecting: false, id: id }))
        .then(() => dispatch(getProfileStat()))
        .then(() => resolve())
        .catch((err: any) => {
          dispatch({ type: APP.REJECT_LOADING_END, isRejecting: false, id: id })
          dispatch({ type: ALERT.SHOW, alert: err && err.response ? 
            alert(err.response.status, 'rejectAttendance', err.response.data && err.response.data.error ? err.response.data.error.code : null ): 
            alert(0, 'rejectAttendance') })
          logger(err, 'error', 'rejectAttendance', false, err && err.response && err.response.data, { id, payload })
          reject()
        })
    })
  }
}

export { getApproval, changeFilter, approveAttendance, rejectAttendance, bulkApprove }