import { SIGNIN, RESET } from '../../definitions'

const initialState = {
  isSigning: false,
  renewLoading: false,
  forgotPassLoading: false,
  resetPassLoading: false,
  isSigningError: false,
  check:{}
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case SIGNIN.ERROR: 
      return Object.assign({}, {
        ...state,
        isSigningError: action.isSigningError
      })
    case SIGNIN.LOADING:
      return Object.assign({}, {
        ...state,
        isSigning: action.isSigning
      })
    case SIGNIN.RENEW_PASS_LOADING:
      return Object.assign({}, {
        ...state,
        renewLoading: action.renewLoading
      })
    case SIGNIN.FORGOT_PASS_LOADING:
      return Object.assign({}, {
        ...state,
        forgotPassLoading: action.forgotPassLoading
      })
  
    case SIGNIN.RESET_PASS_LOADING:
      return Object.assign({}, {
        ...state,
        resetPassLoading: action.resetPassLoading
      })
    case SIGNIN.RESET_PASS_CHECK_SUCCESS:
      return Object.assign({}, {
        ...state,
        check: action.resetPasswordCheck
      })
    case RESET.ALL:
      return initialState

    default:
      return state
  }
}